<template>
  <div class="mb-50">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>中标企业</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="table-header-area">
      <div class="table-header">
        <span class="table-title">中标企业</span>
      </div>
    </div>
    <div class="border-b">
      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        label-position="left"
        :inline="true"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="中标时间：" prop="activeDateIdx">
              <div style="display: flex">
                <span
                  @click="handDate(index)"
                  style="
                    white-space: nowrap;
                    margin-right: 20px;
                    padding: 0 10px;
                    cursor: pointer;
                  "
                  :class="index == activeDateIdx && 'is_active'"
                  v-for="(item, index) in dateList"
                  :key="index"
                  >{{ item.label }}</span
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="activeDateIdx == 6">
          <el-col :span="24">
            <el-form-item label="时 间 段：" prop="winningDate">
              <div style="display: flex">
                <el-date-picker
                  style="width: 200px; margin-right: 20px"
                  v-model="form.startWinningDate"
                  placeholder="开始时间"
                  type="date"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
                <span>至</span>
                <el-date-picker
                  style="width: 200px; margin: 0 20px"
                  v-model="form.endWinningDate"
                  placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                  type="date"
                ></el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item
              prop="provinceCity"
              size="medium"
              label="中标区域："
              class="fs-18"
            >
              <div style="display: flex">
                <el-select
                  style="margin-right: 20px"
                  v-model="form.province"
                  placeholder="请选择省级"
                  @change="onSelectChangeProvince"
                >
                  <el-option
                    v-for="option in provinceOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
                <el-select
                  style="margin-right: 20px"
                  v-model="form.city"
                  placeholder="请选择市级"
                >
                  <el-option
                    v-for="option in cityOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="isLoadingStatus()"
                  style="margin-right: 20px"
                  type="primary"
                  @click="handleQuery"
                  >立即查询</el-button
                >
                <el-button @click="handleReset()">重置</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <generic-table
      ref="genericTable"
      :hasSnCol="false"
      :get-data="getPageData"
      :columns="displayColumns"
      :page-size="20"
      :hide-pagination="false"
      @linkAction="onLinkAction"
    ></generic-table>
  </div>
</template>
<script>
import GenericTable from "@/components/generic-table";
import { getTenderInvitationPage, h5PartnerApplyRegion } from "@/api/aptitude";

export default {
  components: {
    GenericTable,
  },
  data() {
    return {
      activeDateIdx: 1,
      provinceOptions: [],
      cityOptions: [],
      regionList: [],
      formData: {},
      form: {
        province: "",
        city: "",
        startWinningDate: "",
        endWinningDate: "",
      },
      dateList: [
        {
          label: "全部",
          value: 0,
        },
        { label: "当天", value: 1 },
        { label: "近三天", value: 3 },
        { label: "一周内", value: 7 },
        { label: "一个月内", value: 30 },
        { label: "三个月内", value: 90 },
        { label: "自定义时间", value: -1 },
      ],
      displayColumns: [
        {
          label: "企业名称",
          prop: "winningBidder",
          minWidth: 25,
          hasLink: true,
        },
        {
          label: "中标项目",
          prop: "projectName",
          minWidth: 25,
          hasLink: true,
        },
        { label: "业主单位", prop: "biddingUnit", minWidth: 25 },
        { label: "项目金额", prop: "winningAmount", minWidth: 25 },
      ],
    };
  },
  computed: {
    province: {
      get() {
        return this.form.province;
      },
      set(val) {
        this.form.province = val;
      },
    },
    city: {
      get() {
        return this.form.city;
      },
      set(val) {
        this.form.city = val;
      },
    },
    startWinningDate: {
      get() {
        return this.form.startWinningDate;
      },
      set(val) {
        this.form.startWinningDate = val;
      },
    },
    endWinningDate: {
      get() {
        return this.form.endWinningDate;
      },
      set(val) {
        this.form.endWinningDate = val;
      },
    },
  },
  mounted() {
    this.loadH5PartnerApplyRegion();
    this.handDate(1);
  },
  methods: {
    getRouteQueryProvince() {
      let province = "";
      try {
        province = parseInt(this.$route.query.province);        
      } catch(e) {}
      return province;
    },
    isLoadingStatus() {
      return this.$refs.genericTable && this.$refs.genericTable.isLoadingStatus;
    },
    validate() {
      if (this.startWinningDate && this.endWinningDate) {
        var startDateObj = new Date(this.startWinningDate);
        var endDateObj = new Date(this.endWinningDate);
        // Compare start date and end date
        if (startDateObj > endDateObj) {
          this.$message.error("开始时间不能大于结束时间");
          return false;
        }
      }
      return true;
    },
    handleQuery() {
      if (!this.validate()) return;

      let formData = { ...this.form };
      if (this.province)
        formData.province = this.getProvinceName(this.province);
      if (this.city) formData.city = this.getCityName(this.province, this.city);
      console.log("handleQuery-->formData=", formData);
      this.formData = formData;
      this.reloadTablePage(1);
    },
    handleReset() {
      console.log("handleReset...");
      if (this.$refs.form) this.$refs.form.resetFields();
      this.province = "";
      this.city = "";
      this.startWinningDate = "";
      this.endWinningDate = "";
      this.handDate(1);
    },
    reloadTablePage(current) {
      this.current = current;
      if (this.$refs.genericTable) this.$refs.genericTable.reload(this.current);
    },
    async getPageData({ size, current }) {
      this.size = size;
      this.current = current;
      let params = {
        size,
        current,
      };
      Object.assign(params, this.formData);
      if (this.activeDateIdx == 1) {
        this.handDate(1);
        params.startWinningDate = this.startWinningDate;
        params.endWinningDate = this.endWinningDate;
      }
      console.log("getPageData-->params=", params);
      let records = [];
      let total = 0;
      try {
        // 获取数据的 API 请求
        const response = await getTenderInvitationPage(params);
        records = response.data ? response.data.records : [];
        total = response.data ? response.data.total : 0;
      } catch (res) {
      } finally {
      }
      return {
        data: records,
        total: total,
      };
    },
    onLinkAction(prop, row) {
      console.log("linkAction", prop, row);
      if (prop == "winningBidder") {
        this.$router.push({
          path: "/qualification/detail/",
          query: { unicode: row.winningBidderCode },
        });
      } else if (prop == "projectName") {
        this.$router.push({
          path: "/project/details/",
          query: { prjnum: row.projectNo },
        });
      }
    },
    async loadH5PartnerApplyRegion() {
      const response = await h5PartnerApplyRegion();

      let records = response.data ? response.data : [];
      this.regionList = records;
      this.provinceOptions = this.extractProvinceOptions();
      let province = this.getRouteQueryProvince();
      if (province) {
        this.onSelectChangeProvince(province);
        this.handleQuery();
      }
    },
    extractProvinceOptions() {
      let options = [];
      (this.regionList || []).forEach((item) => {
        let opt = {
          label: item.name,
          value: item.adCode,
        };
        options.push(opt);
      });
      return options;
    },
    extractCityOptions(province) {
      let options = [];
      let target = (this.regionList || []).find(
        (item) => item.adCode == province
      );
      if (target) {
        (target.child || []).forEach((item) => {
          let opt = {
            label: item.name,
            value: item.adCode,
          };
          options.push(opt);
        });
      }
      return options;
    },
    onSelectChangeProvince(value) {
      this.province = value;
      this.city = "";
      this.loadCities(value);
    },
    loadCities(province) {
      console.log("loadCities-->province=" + province);
      this.cityOptions = this.extractCityOptions(province);
    },
    getProvinceName(province) {
      let target = (this.regionList || []).find(
        (item) => item.adCode == province
      );
      return target ? target.name : "";
    },
    getCityName(province, city) {
      let targetCity = null,
        target = (this.regionList || []).find(
          (item) => item.adCode == province
        );
      if (target) {
        targetCity = (target.child || []).find((item) => item.adCode == city);
      }
      return targetCity ? targetCity.name : "";
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      console.log(dateString);
      return dateString;
    },
    getNearDates(offsetDays) {
      const nearDates = [];
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const todayString = `${year}-${month}-${day}`;
      const nearDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - offsetDays
      );
      const nearYear = nearDate.getFullYear();
      const nearMonth = (nearDate.getMonth() + 1).toString().padStart(2, "0");
      const nearDay = nearDate.getDate().toString().padStart(2, "0");
      const nearDateString = `${nearYear}-${nearMonth}-${nearDay}`;
      nearDates.push(nearDateString);
      nearDates.push(todayString);
      console.log(nearDates);
      return nearDates;
    },
    handDate(index) {
      this.activeDateIdx = index >= 0 ? index : -1;
      let target = index >= 0 ? this.dateList[index] : null;
      if (target) {
        // Calculate startWinningDate & endWinningDate
        if (target.value == 0) {
          this.startWinningDate = "";
          this.endWinningDate = "";
        } else if (target.value == 1) {
          const currentDate = this.getCurrentDate();
          this.startWinningDate = currentDate;
          this.endWinningDate = currentDate;
        } else if (target.value == -1) {
          // Custom date range.
        } else {
          const nearDates = this.getNearDates(target.value);
          this.startWinningDate = nearDates[0];
          this.endWinningDate = nearDates[1];
        }
      }
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-start;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px;
}
.table-header-area {
  display: flex;
}
.table-header {
  width: 520px;
  height: 60px;
  background: linear-gradient(90deg, #1890ff 0%, #ffffff 100%);
  background-blend-mode: lighten;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: left;
  margin-top: 20px;
  padding-left: 20px;
  /* background-image: url(~@/assets/images/bjtsy/jx7771.png); */
  background-repeat: no-repeat;
  background-size: cover;
}

.table-title {
  width: 233px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 60px;
}
.right-header {
  text-align: right;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 20px;
  flex: 1;
}
.view-more {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #888888;
  line-height: 60px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.view-more::after {
  content: "";
  background-image: url(~@/assets/images/more11941.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.border-b {
  height: auto;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding: 20px;
}
.txt-fs {
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.txt-wh {
  height: 40px;
  line-height: 40px;
  padding: 0px 12px 0px 12px;
  /* background-color: #928e8e; */
}
.ml-20 {
  margin-left: 20px;
}

div .el-form-item__label {
  text-align-last: justify !important;
  text-align: justify !important;
}
.is_active {
  color: #007ef2;
  background-color: #edf4ff;
  border-radius: 5px;
}
::v-deep .el-button--primary {
  background-color: #007ef2;
  border-color: #007ef2;
}
</style>
